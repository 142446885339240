import React, { useState, useEffect } from "react";
import Skeleton from 'react-loading-skeleton';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Outlet
} from "react-router-dom";
const Home = () => {
    return (
        <>
            <div className="hrrd-hero_1  positioning ">
                <div className="container-fluid bg-gray">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                                <div className="hrrd-hero-content">
                                    <h1 className="section-heading">NGO s we love and support</h1>

                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-center">
                                <p><a href="#">Community </a>/<b><a href="#">NGO s we love and support</a> </b></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                        <div className="about-img-2">
                            <img
                                 src="assets/images/NGO.webp"
                                 alt="aPLS web development - Marketing content" />
                        </div>
                    </div>
                    <div className="col-md-5 offset-md-1">
                        <h1 className="section-heading pb-3">NGO s we love and support</h1>
                        <p className="section-paragraph">
                        From the era of support to the era of empowerment                        </p>
                    </div>
                </div>
            </div>
            <div class="space-bar"></div>
            <div className="container pt-50">
                <div className="row align-items-center justify-content-between">

                    <div className="col-md-5 pt-40 pb-4">
                        <h1 className="section-heading ">Digital social services for lifelong support</h1>
                        <div className=" pt-4">
                            <div className=" pt-4">
                                <p>
                                Alongside helping vulnerable individuals achieve self-sufficiency, social service agencies will be more likely to offer active, ongoing support to everyone in society. This includes everything from job assistance to welfare assistance and payment to children's welfare and service for the elderly.
                                                                </p>
                                                                <div className=" pt-2"></div>

                                <p>
                                Our goal is to improve the lives of people by assisting people with interpersonal and social issues while helping to promote human rights and wellbeing. Social workers guard kids and adults with care needs from danger. From helping keep families under pressure to helping those who have a mental illness, social work can be an extremely demanding, challenging, often emotionally charged, and rewarding job.
                                                                </p>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 offset-md-1">
                        <div className="about-img-2">
                            <img
                                src="assets/images/support11.webp"
                                alt="aPLS web development - Marketing content" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="space-bar"></div>






        </>
    )

}
export default Home