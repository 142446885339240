import React, { Component } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";

import Home from "./components/Home";
import Demo from "./components/Demo"
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Fintech from "./components/Industries/Fintech";
import Education from "./components/Industries/Education";
import Careers from "./components/Community/Careers";
import NgoWeLoveAndSupport from "./components/Community/NgoWeLoveAndSupport";
import CommunityCenter from "./components/Community/CommunityCenter";


import Jobdetails from "./components/Community/Jobdetails";
import Retail from "./components/Industries/Retail&eCommerce";
import Transport from "./components/Industries/Transport";
import Medical from "./components/Industries/Medical";
import About from "./components/About";
import Enterprise_Application from "./components/Services/EnterpriseApplications";
import WebsiteDevelopment from "./components/Services/Websitedevelopment";
import SoftwareDevelopment from "./components/Services/SoftwareDevelopment";
import MobileApplication from "./components/Services/MobileapplicationDevelopment";
import CustomisedSoftwareDevelopment from "./components/Services/CustomisedSoftwareDevelopment";
import DataScience from "./components/Services/DataScienceAnalytics";
import DigitalMarketing from "./components/Services/DigitalMarketing";
import Automation from "./components/Services/AutomationAndAI";
import Cloud from "./components/Services/Cloud";
import BusinessAutomation from "./components/Services/BusinessAutmation";
import Itsupport from "./components/Services/Itsupport";
import Coming from "./components/commingsoon";

import Blog from "./components/Blog/Blog";
import Blogpage from "./components/Blog/BlogDetail";


function App() {
  return (
    <>
      <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/discover-aPLS" element={<About />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/fintech" element={<Fintech />} />
          <Route path="/education" element={<Education />} />
          <Route path="/retail-and-eCommerce" element={<Retail />} />
          <Route path="/transport" element={<Transport />} />
          <Route path="/medical" element={<Medical />} />
          <Route path="/Comingsoon" element={<Coming />} />
          <Route path="/enterprise-application" element={<Enterprise_Application />} />
          <Route path="/software-development" element={<SoftwareDevelopment />} />
          <Route path="/mobile-application" element={<MobileApplication />} />
          <Route path="/customised-software-development" element={<CustomisedSoftwareDevelopment />} />
          <Route path="/data-science-and-analytics" element={<DataScience />} />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/automation" element={<Automation />} />
          <Route path="/cloud" element={<Cloud />} />
          <Route path="/business-autmation" element={<BusinessAutomation />} />
          <Route path="/itsupport" element={<Itsupport />} />
          <Route path="/website-development" element={<WebsiteDevelopment />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/ngo-we-love-andsupport" element={<NgoWeLoveAndSupport />} />
          <Route path="/community-center" element={<CommunityCenter />} />

          <Route path="/news" element={<Blog />} />

          <Route path="/jobdetails/:id" element={<Jobdetails />} />
          <Route path="/news-details/:slug" element={<Blogpage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
    </>
  );
}

export default App;
