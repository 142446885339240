import React, {useState ,useEffect} from "react";
import Skeleton from 'react-loading-skeleton';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Outlet
} from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const options = {
    items: 2,
    loop: true,
    smartSpeed: 1500,
    autoplay: false,
    dots: true,
    margin:24,
    nav: true,
    navText : ["<i class='fas fa-long-arrow-alt-left'></i>","<i class='fas fa-long-arrow-alt-right'></i>"],
    responsiveClass:true,
    responsive:{
        0:{
            items:1,
            nav:true
        },
        600:{
            items:2,
            nav:false
        },

    }

};
const Products = () => {

    const [data, setData]= useState([]);
    const [filter,setFilter] = useState(data);
    const [loading, setLoading] = useState(false);
    let componentMounted = true;

    useEffect(() => {
        const getProducts = async () => {
            setLoading(true);
            const response =await fetch("https://aplswd.com/api/joblist.php");
            //console.log(response);
            // console.log(response.json());
            if(componentMounted){
                setData(await response.clone().json());
                setFilter(await response.json());
                setLoading(false);
                //  console.log(filter);
            }
            return () =>{
                componentMounted =false;
            }
        }
        getProducts();
    }, []);

    const filterProduct = (cat) => {
        const updateList = data.filter((x)=> x.category === cat);
        setFilter(updateList);
    }

    const Loading = () => {
        return(
            // <p className="pt-5 text-center">
            //     <div className="spinner-border" role="status">
            //         <span className="visually-hidden">Loading...</span>
            //     </div> Loading....</p>

            <>
                <div className="col-md-3">
                    <Skeleton height={350}/>
                </div>

                <div className="col-md-3">
                    <Skeleton height={350}/>
                </div>

                <div className="col-md-3">
                    <Skeleton height={350}/>
                </div>

                <div className="col-md-3">
                    <Skeleton height={350}/>
                </div>
            </>

        )
    };

    const ShowProducts = () => {

        return (
            <>
                <OwlCarousel className="slider-items owl-carousel" {...options}>
                    {filter.map((product) =>{
                       // console.log(product);
                        return(
                            <>

                                <div className="single-client-testimonial single-about-team-content item job_card" key={product.id}>
                                    <h6><b>{product.title}</b></h6>
                                    <p className="line-height-20 pt-4">
                                        {product.description.substring(0,220)}...
                                    </p>
                                    <div className="row pt-3">
                                        <div className="col-md-6">
                                            <div className="contact-details-info">
                                                <ul>
                                                    <li><i className="bi bi-clock"></i> Type: {product.type}
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="contact-details-info">
                                                <ul>
                                                    <li><i className="bi bi-clock"></i> Experience :{product.experience}
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                        <p className="text-end pt-3">
                                            <Link to={`/jobdetails/${product.id}`} type="button" className="view_more"> View More</Link>
                                            <Outlet />
                                        </p>
                                    </div>
                                </div>



                            </>
                        )
                    })}
                </OwlCarousel>
            </>
        );
    };

    return(
        <>


            <div className="apps-area positioning pb-76 ">
                <div className="container">
                    <div className="row text-center">

                        <h2 className="section-heading">Our Current openings</h2>


                        <p>We have 700 plus official workers and thousands of remote workers. <br />They do work them
                            dedicated and they are expert in the particular topic.</p>

                    </div>
                    <div className="row pt-50">

                        {loading ? <Loading /> : <ShowProducts/>}


                    </div>
                </div>
                <img className="shape hrrd-app-shape" src="assets/images/shape/hrrd-app.png" alt="" />
            </div>




        </>
    )

}
export default Products