import React, { useState, useEffect } from "react";
import Skeleton from 'react-loading-skeleton';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Outlet
} from "react-router-dom";
import Currentopening from './Currentopening';
const Home = () => {
    return (
        <>
                       <div className="hrrd-hero_1 bg_half_2 positioning ">
                <div className="container ">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="hrrd-hero-content">
                                <h1 className="section-heading">Careers</h1>
                                <p>
                                    Workplace culture encourages innovation, and you are expected to be your best.
                                </p>
                                <div className="hrrd-hero-btn-wrap">
                                    <a className="common-btn btn-hrrd-1" data-bs-toggle="modal"
                                        data-bs-target="#right_modal_sm">Get in touch</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="hrrd-hero-img-wrap">
                                <div className="hrrd-hero-img">
                                    <img src="assets/images/httpscloud.webp" className="img-fluid" alt="aPLS web development - Fintech" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="shape hrrd-hero-shape" src="assets/images/shape/hrrd-hero-shape.png" alt="image" />
                <img className="shape hrrd-hero-shape-top" src="assets/images/shape/hrrd-hero-shape-top.png" alt="image" />
            </div>
            <div class="space-bar"></div>
            <div className="container pt-50">
                <div className="row align-items-center justify-content-between">

                    <div className="col-md-5 pt-40 pb-4">
                        <h1 className="section-heading ">Innovating in a truly human way</h1>
                        <div className=" pt-4">
                            <div className=" pt-4">
                                <p>
                                    We work with our clients, partners, and communities to create solutions that will improve the lives and futures of millions all over the globe. We live by our Code of Ethics every single day.
                                </p>
                                <p>
                                    Our multi-functional spaces are available for virtual and onsite work. They support innovation, creativity, and teamwork.
                                </p>
                                <p>
                                    We invest in technology and spaces to support the global community of people and talent that are innovating together. We blend comfort and productivity in areas that are inviting, familiar, environmentally sustainable, and physically accessible for all.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 offset-md-1">
                        <div className="about-img-2">
                            <img
                                src="assets/images/clients.webp"
                                alt="aPLS web development - Marketing content" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="space-bar"></div>

            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                        <div className="about-img-2">
                            <img
                                src="assets/images/Work.webp"
                                alt="aPLS web development - Marketing strategy" />
                        </div>
                    </div>
                    <div className="col-md-5 offset-md-1">
                        <h1 className="section-heading">Work your way
                        </h1>
                        <div className="scroll_content_1 pt-4">
                            <div className=" pt-4">
                                <p><b>Work your way:</b></p>
                                <p>
                                    You can balance your personal and professional life using various workplace options and innovative tools to help you stay connected worldwide.                         </p>
                                <div className=" pt-4"></div>

                                <p className="section-paragraph"><i className="fa fa-check text-warning"></i> &nbsp;
                                    <b>Flexible schedule: </b>
                                    We offer various voluntary options, such as job-sharing, part-time, and flexible-time schedules.
                                </p>
                                <p className="section-paragraph pt-3"><i className="fa fa-check text-warning"></i> &nbsp;
                                    <b>Telecommute: </b>
                                    You can work from anywhere other than the Accenture office or project site.
                                </p>
                                <p className="section-paragraph pt-3"><i className="fa fa-check text-warning"></i> &nbsp;
                                    <b>Client side flexibility: </b>
                                    Fly-back flights, short workweeks, and extended weekends allow you to manage your time away from home.
                                </p>

                            </div>
                        </div>
                        <p className="text-end"><small><i>Scroll to read more</i></small></p>

                    </div>
                </div>
            </div>

            <div class="space-bar"></div>
            <div className="container pt-50">
                <div className="row align-items-center justify-content-between">

                    <div className="col-md-5 pt-40 pb-4">
                        <h1 className="section-heading ">Stay connected</h1>
                        <div className="scroll_content_1 pt-4">

                            <div className=" pt-4">
                                <p className="section-paragraph pt-3"><i className="fa fa-check text-warning"></i> &nbsp;
                                    <b>Join our team: </b>
                                    Find open positions that suit your interests and skills. We are looking for team members who are passionate, creative, curious and driven to find solutions.
                                </p>
                                <p className="section-paragraph pt-3"><i className="fa fa-check text-warning"></i> &nbsp;
                                    <b>Keep upto date: </b>
                                    Get career tips, industry insights, and insider perspectives from people who work here to stay ahead.                                </p>
                                <p className="section-paragraph pt-3"><i className="fa fa-check text-warning"></i> &nbsp;
                                    <b>Job alerts Email: </b>
                                    To receive job alerts, the latest news, and insider tips, you can personalize your subscription. Discover the rewarding and exciting opportunities that await you.
                                </p>
                                <div className=" pt-4"></div>

                                <b>
                                    Supercharge your career with aPLS: </b><p>
                                    Are you passionate about technology but not sure where or how to begin? aPLS is a career platform that helps you realize your tech goals. Learn new skills, be ready for the future, thrive in tech, and get guidance from our leaders.

                                </p>
                                <div className=" pt-4"></div>

                                <b>Work you will do: </b><p>
                                    Combine industry knowledge, innovation, and intelligence with the latest technologies to help clients innovate at scale and transform businesses.
                                </p>
                                <div className=" pt-4"></div>
                                <p>
                                    Our intelligent software engineering services can help you transform your innovative ideas into competitive advantages.
                                </p>
                                <div className=" pt-4"></div>
                                <p>
                                    There are many roles that we offer with limitless possibilities. Join us as an Application Developer Associate, Cloud Migration and Implementation Associate, Security Delivery Associate, Application/Cloud Associate, and many other roles.

                                </p>

                            </div>
                        </div>
                        <p className="text-end"><small><i>Scroll to read more</i></small></p>

                    </div>

                    <div className="col-md-6 offset-md-1">
                        <div className="about-img-2">
                            <img
                                src="assets/images/connected.webp"
                                alt="aPLS web development - Marketing content" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="space-bar"></div>

            <div className="popular-jobs-area  ">
                <img className="shape popular-jobs-shape" src="assets/images/shape/popular-job-shape.png" alt="image" />
                <div className="container ">

                    <h2 className="section-heading text-center">What’s on offer
                    </h2>
                    <div className="trip-section-title text-center ">
                        aPLS offers outstanding development opportunities, attractive rewards packages,<p>
                            and a competitive salary. This is the perfect place to start your career.</p>
                    </div>
                    <div className="row mt-50 justify-content-center" align="center">

                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="trip-service-box text-center enterprise-service-card-ht">
                                <div className="trip-service-icon">
                                    <i className="fa fa-check-circle icon_bg" aria-hidden="true"></i>
                                </div>
                                <div className="scroll_content_1 pt-4">

                                    <div className="trip-service-content">
                                        <h2>Diverse and innovative work</h2>
                                    </div>
                                    <p>
                                        Our main clients in different industries trust us to reinvent how every job is done. Employees are encouraged to explore different areas of the company. We believe that together, we are more than ever. Our  employees in different countries can work as one seamless network because of our commitment to equality and our belief that "together we are greater than any other."
                                    </p>
                                </div>
                                <p className="text-end"><small><i>Scroll to read more</i></small></p>

                            </div>

                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="trip-service-box text-center enterprise-service-card-ht">
                                <div className="trip-service-icon">
                                    <i className="fa fa-check-circle icon_bg" aria-hidden="true"></i>
                                </div>

                                <div className="trip-service-content">
                                    <h2>Training and development</h2>
                                </div>
                                <p>
                                    We offer online and on-the-job training to help students transition from university into the workforce. To help you succeed, we will provide extensive training and a dedicated career counselor.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="trip-service-box text-center enterprise-service-card-ht">
                                <div className="trip-service-icon">
                                    <i className="fa fa-check-circle icon_bg" aria-hidden="true"></i>
                                </div>
                                <div className="trip-service-content">
                                    <h2>Making a difference</h2>
                                </div>
                                <p>
                                    Our people have limitless opportunities to work with clients in all industries and technologies. We unlock value and make a tangible impact on the communities. This is a step towards a more sustainable future.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="trip-service-box text-center enterprise-service-card-ht">
                                <div className="trip-service-icon ">
                                    <i className="fa fa-check-circle icon_bg" aria-hidden="true"></i>
                                </div>
                                <div className="trip-service-content">
                                    <h2>inclusive and social culture
                                    </h2>
                                </div>
                                <p>
                                    From day one, we welcome new colleagues and help them to connect, collaborate, and build strong relationships at the office. We foster trust and collaboration, especially in an environment that combines digital and physical ways of working.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="space-bar"></div>
            <Currentopening />


        </>
    )

}
export default Home