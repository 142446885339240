import React, { useState, useEffect } from "react";
import Skeleton from 'react-loading-skeleton';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Outlet
} from "react-router-dom";
const Home = () => {
    return (
        <>
            <div className="hrrd-hero_1  positioning ">
                <div className="container-fluid bg-gray">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                                <div className="hrrd-hero-content">
                                    <h1 className="section-heading">Community Center</h1>

                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-center">
                                <p><a href="#">Community </a>/<b><a href="#">Community Center</a> </b></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                        <div className="about-img-2">

                            <img src="assets/images/Community1-min.webp" className="img-fluid" alt="aPLS web development - Fintech" />
                        </div>
                    </div>
                    <div className="col-md-5 offset-md-1">
                        <h1 className="section-heading">Community Center</h1>
                        <div class="pt-3"></div>
                        <p className="section-paragraph">
                            We are creating a culture that involves defining some ideals for the workplace and then requiring employees to conform to the guidelines (written as well as not reported), which form the basis of the culture. However, placing rules in front of individuals is the most effective method to make those in the culture feel excluded.
                        </p>
                    </div>
                </div>
            </div>

            <div className="space-bar"></div>
            <div className="featured-area positioning  pb-76">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="hrdd-section-title text-center wow animate fadeInDown"
                                data-wow-delay="100ms" data-wow-duration="1500ms">
                                {/* <h2 className="section-heading">We Provide for You</h2> */}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-50">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 wow  zoomIn  animated"
                            data-wow-delay="200ms" data-wow-duration="1500ms">
                            <div className="featured-box">
                              
                                <div className="featured-content">
                                    <h2>create and consistently consider core values</h2>

                                    <p>
                                        Without values, there's no culture. So, develop core values that people can believe in. If you've got them, you'll see that the employees share an idea of the company and each other. It's not enough to make these values. Values evolve and constantly reflect on why the business exists and why its employees and customers are drawn to it. Take note of the role employees plays in defining the business's purpose.

                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 wow animate zoomIn"
                            data-wow-delay="400ms" data-wow-duration="1500ms">
                            <div className="featured-box">
                                
                                <div className="featured-content">
                                    <h2>Hire for values</h2>
                                    <p>
                                        Interviews don't include questions about credentials and experience. All applicants have been thoroughly vetted. Instead, I ask potential employees what motivates them to get out of bed each day, what life experiences have shaped their lives, and what makes them feel fulfilled. These core motivations will help you determine what kind of employee you will be.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 wow animate zoomIn"
                            data-wow-delay="600ms" data-wow-duration="1500ms">
                            <div className="featured-box">
                               
                                <div className="featured-content">
                                    <h2>Address values violations individually</h2>
                                    <p>
                                        Do not let outlier behaviour dictate the rules. Many organizations resort to "progressive discipline," which places the responsibility on employees to improve and make corrections when necessary. This type of discipline can be used in court. However, performance counselling is legal. Managers can use this style to communicate specific issues, identify root problems, and then state the consequences of those problems. Instead of choosing the negative option, choose this route.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 wow animate zoomIn"
                            data-wow-delay="800ms" data-wow-duration="1500ms">
                            <div className="featured-box">
                                
                                <div className="featured-content">
                                    <h2>Give responsibility, not tasks</h2>
                                    <p>
                                        Ownership and setting goals are the foundation of responsibility. People who own their goals think differently about achieving them and are more passionate than if they do the tasks on their to-do lists. Bridgeway Capital Management Houston organizes meetings as "fishbowls". There is a shared decision-making process that reaches the company's overall goals. This unique process keeps employees engaged in thinking about ways to improve their company.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 wow animate zoomIn"
                            data-wow-delay="1000ms" data-wow-duration="1500ms">
                            <div className="featured-box">
                               
                                <div className="featured-content">
                                    <h2>Turn that well known cliche on its head</h2>
                                    <div className="scroll_content_1 ">

                                        <p>
                                            The destination, not the journey, is what matters when building culture. Instead of focusing on the journey to success, think more about the people taking the company there. To achieve faster results, allow employees to set clear goals and access various tools. They will feel valued if they have these tools and plans.

                                        </p>
                                        <p>
                                            Do not be a slave of "culture." People within communities are the driving force behind their power and effectiveness. Great communities accept people with the right mindset, don't expect anyone else to be like them, and it is possible to build one.

                                        </p>
                                        <p>
                                            Community companies offer a platform for stakeholders and customers to interact with one another. Spinks' new book outlines how companies can work together to create and serve a community. This includes everything from software businesses to neighbourhood groups.

                                        </p>
                                    </div>
                                    <p className="text-end pt-2 "><small><i>Scroll to read more</i></small></p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="shape featured-shape" src="assets/images/shape/feasured-hrrd.png" alt="" />
            </div>


            <div class="space-bar"></div>

            <div className="container pb-76">
                <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="trip-section-title text-center ">
                            <h1>We would like to help to<p> build a better community</p></h1>
                            <br></br>
                        </div>
                    </div>
                </div>
                <div className="featured-area positioning mt-70 pb-76" id="">
                    <div className="container">
                        <div className="row justify-content-center mt-50">
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 ">
                                <div className="featured-box">
                                    <div className="featured-icon">
                                        <img src="assets/images/home1.png" alt="Requirement Analysis" width="70px" />
                                    </div>
                                    <div className="featured-content">
                                        <h2>Social enterprisedevelopment</h2>
                                        <p>
                                            We partner with your charity or organization to develop and launch a social enterprise that will sustain your social impact.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center ">
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 ">
                                <div className="featured-box">
                                    <div className="featured-icon">
                                        <img src="assets/images/home2.png" alt="Solution Development" width="70px" />
                                    </div>
                                    <div className="featured-content">
                                        <h2>Custom product</h2>
                                        <p>
                                            We design and create custom products for your company that spark a discussion about a cause that you are committed to.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 ">
                                <div className="featured-box">
                                    <div className="featured-icon">
                                        <img src="assets/images/home4.png" alt="web Development" width="50px" />
                                    </div>
                                    <div className="featured-content">
                                        <h2>Community project</h2>
                                        <p>
                                            We develop community-based projects that bring awareness to social issues vital to our society.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 ">
                                <div className="featured-box">
                                    <div className="featured-icon">
                                        <img src="assets/images/home3.png" alt="web Development" width="100px" />
                                    </div>
                                    <div className="featured-content">
                                        <h2>Shop products</h2>
                                        <p>
                                            We create the community to make a change by inviting members to purchase items designed by people who have lived it.                                         </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className="shape featured-shape" src="assets/images/shape/feasured-hrrd.png" alt="image" />
                </div>
            </div>

            <div className="popular-jobs-area  ">
                <img className="shape popular-jobs-shape" src="assets/images/shape/popular-job-shape.png" alt="image" />
                <div className="container ">
                    <div className="row mt-50 justify-content-center" align="center">

                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="trip-service-box text-center home_service">
                                <div className="trip-service-icon ">
                                    <i className="fa fa-chart-bar icon_bg" aria-hidden="true"></i>
                                </div>
                                <div className="trip-service-content">
                                    <h2>Just enough leadership
                                    </h2>
                                </div>
                                <div className="scroll_content_1 ">

                                    <p className="pb-3">
                                        However, the community certainly uses leadership, not the self-centred, "heroic" kind that has taken over the business world. We make a great fuss these days about the evils of micromanaging--managers' meddling in the affairs of their subordinates. Much more dangerous can be "macro leading": uninvolved leaders' exercise of power from above. Community leadership is a more straightforward type of leadership, described as distributed and engaged management. The community leader is actively involved in inspiring others, ensuring that everybody can be applied. If you're sceptical that this will be the case, look at how Wikipedia, Linux, and other open-source projects operate.
                                    </p>
                                    {/* <Link to="/Enterprise-Application" className="text-muted pt-3">Read more <i className="bi bi-arrow-right-short"></i></Link> */}
                                </div>
                                <p className="text-end pt-2 "><small><i>Scroll to read more</i></small></p>

                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="trip-service-box text-center home_service">
                                <div className="trip-service-icon ">
                                    <i className="fa fa-window-maximize icon_bg" aria-hidden="true"></i>
                                </div>
                                <div className="trip-service-content">
                                    <h2>From top down to middle ou
                                    </h2>
                                </div>
                                <div className="scroll_content_1 ">

                                    <p className="pb-3">
                                        What can be done to rebuild communities and companies? Unfortunately, most of the hundreds of articles and books on managing large-scale change--transformation, revitalization, turnaround--focus on leadership. John Katter’s "Leading Change" (HBR Classic January 2007) is a famous example. He outlines eight phases. Next, create a solid guiding coalition in which senior managers always form the core. This coalition should then create a vision that is shared with others so they can carry it out. Next, you will plan short-term wins and consolidate improvements. Finally, you will institutionalize new approaches.

                                    </p>
                                    {/* <Link to="/WebsiteDevelopment" className="text-muted pt-3">Read more <i className="bi bi-arrow-right-short"></i></Link> */}
                                </div>
                                <p className="text-end pt-2 "><small><i>Scroll to read more</i></small></p>

                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="trip-service-box text-center home_service">
                                <div className="trip-service-icon">
                                    <i className="fa fa-window-maximize icon_bg" aria-hidden="true"></i>
                                </div>
                                <div className="trip-service-content">
                                    <h2>The remnants of community</h2>
                                </div>

                                <p className="pb-3">
                                    It's much easier to continue building on existing communities than it is to start one. I have found that many companies who seem to have lost their sense of community have it somewhere, even though the leaders do not appreciate it. Clusters of scientists dedicated to finding cures for diseases can be found in large pharmaceutical companies, which have become bloated behemoths.

                                </p>

                                {/* <Link to="/" className="text-muted pt-3">Read more <i className="bi bi-arrow-right-short"></i></Link> */}

                            </div>

                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="trip-service-box text-center home_service">
                                <div className="trip-service-icon">
                                    <i className="fa fa-mobile icon_bg" aria-hidden="true"></i>
                                </div>
                                <div className="trip-service-content">
                                    <h2>An atmosphere that promotes trust</h2>
                                </div>
                                <div className="scroll_content_1 ">

                                    <p className="pb-3">
                                        To rebuild a community, you must stop destroying it. This includes treating people as human resources, firing them frequently if the company fails to meet its performance targets, and accepting obscene compensation packages (especially those that offer CEOs "retention" and bonuses for doing the work they are paid); showing disrespect for the company's history, culture, and leadership. The organization must abandon its individualistic behaviour and many short-term measures to promote trust, engagement and spontaneity, which will lead 	to sustainability.

                                    </p>
                                    {/* <Link to="/" className="text-muted pt-3">Read more <i className="bi bi-arrow-right-short"></i></Link> */}
                                </div>
                                <p className="text-end pt-2 "><small><i>Scroll to read more</i></small></p>

                            </div>
                        </div>
                    </div>

                    <div className="collapse" id="collapseExample">
                        <div className="row justify-content-center" align="center">

                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="trip-service-box text-center home_service">
                                    <div className="trip-service-icon">
                                        <i className="fa fa-paint-brush icon_bg" aria-hidden="true"></i>
                                    </div>
                                    <div className="trip-service-content">
                                        <h2>A robust culture</h2>
                                    </div>
                                    <div className="scroll_content_1 ">

                                        <p className="pb-3">
                                            A robust and compelling culture is necessary to create an atmosphere and allow the community to flourish. The place must be understood by everyone. Google employees know their mission is to "organize the world's information and make it universally accessible and useable." Without a compelling culture, a company is like a person with no personality. They have flesh and bones but no life force and no soul. Respectful relationships and committed employees are the best way to make organizations work. This is what causes the entire institution to collapse, as can be seen in many company.

                                        </p>
                                        {/* <Link to="/" className="text-muted pt-3">Read more <i className="bi bi-arrow-right-short"></i></Link> */}
                                    </div>
                                    <p className="text-end pt-2 "><small><i>Scroll to read more</i></small></p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="trip-service-box text-center home_service">
                                    <div className="trip-service-icon">
                                        <i className="fa fa-bullseye icon_bg" aria-hidden="true"></i>
                                    </div>
                                    <div className="trip-service-content">
                                        <h2>Leadership at the centre</h2>
                                    </div>
                                    <div className="scroll_content_1 ">

                                        <p className="pb-3">
                                            To build a strong community, leadership must be different from the ones that drive transformation from the top. Community leaders see themselves as being at the centre, reaching out instead of looking down. They can facilitate change and recognize that not all of it can be done by themselves. Jeff Imelda of General Electric wants the company to be as well-known for its innovation and organic growth as its operational excellence. He encourages the teams in charge of businesses to determine the necessary transformation.
                                        </p>
                                        {/* <Link to="/" className="text-muted pt-3">Read more <i className="bi bi-arrow-right-short"></i></Link> */}
                                    </div>
                                    <p className="text-end pt-2 "><small><i>Scroll to read more</i></small></p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="trip-service-box text-center home_service">
                                    <div className="trip-service-icon">
                                        <i className="fa fa-cogs icon_bg" aria-hidden="true"></i>
                                    </div>
                                    <div className="trip-service-content">
                                        <h2>Developing community
                                        </h2>
                                    </div>
                                    <div className="scroll_content_1 ">

                                        <p className="pb-3">
                                            These reflections can naturally lead to small initiatives that grow into comprehensive strategies. Strategy is often thought of as deliberately formulated at the top and then implemented down. In my years of research, I found that companies learn interesting techniques from small ventures created by all kinds of people.

                                        </p>
                                        <p className="pb-3">
                                            According to the traditional view, the chief executive is seated on top of a pyramid. Imagine yourself sitting on top of an Egyptian pyramid. You can't see the inside, and it's impossible to tell what's going on below. Managers in an open hierarchy may be better able to see the connections between strategy and operations.

                                        </p>
                                        <p className="pb-3">
                                            Middle managers are often the best at recognizing what needs to change. It is incredible to me how a simple idea can grow into a primary strategy or change within an organization when it is put in the hands and embraced by people who can see the bigger picture.
                                        </p>
                                        {/* <Link to="/" className="text-muted pt-3">Read more <i className="bi bi-arrow-right-short"></i></Link> */}
                                    </div>
                                    <p className="text-end pt-2 "><small><i>Scroll to read more</i></small></p>
                                </div>
                            </div>



                        </div>
                    </div>
                    <p className="text-end">
                        <a data-bs-toggle="collapse" href="#collapseExample" role="button"
                            aria-expanded="false" aria-controls="collapseExample">
                            View more services
                        </a>

                    </p>
                </div>
            </div>
            <div class="space-bar"></div>


        </>
    )

}
export default Home