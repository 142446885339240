import React from "react";
import Getintouch from "./Getintouch";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Helmet} from "react-helmet";

const options = {
    items: 1,
    loop: true,
    smartSpeed: 1500,
    autoplay: false,
    dots: true,
    margin:24,
    nav: true,
    responsiveClass:true,
    responsive:{
        0:{
            items:1,
            nav:true
        },
    }
};
const Home = () =>{
    return(
     <> <Helmet>
         <title>aPLS web development</title>
         <meta name="description" content="Grow your brand fast with aPLS web development. We deliver quality creative services to our clients in all the domains of digital marketing, web development." data-react-helmet="true"/>
         <meta name="Keyword" content=" Web development, software development, digital marketing company, best website development company in india, best software development company, automation and ai services, website and software development company, customized software development services, IT company in india, Education services" data-react-helmet="true"/>
        </Helmet>
         <div className="hrrd-hero positioning d-lg-block d-none d-sm-none cover-section">
             <div className="container">
                 <div className="row align-items-center justify-content-between">
                     <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                         <div className="hrrd-hero-content">
                             <h1 className="section-heading">Professional Website & Software Development</h1>
                             <p className="pt-3">Welcome to the world of automation where we automate the complete process using cross platform languages and AI. If you have an IT requirement, we have a solution.</p>
                             <div className="hrrd-hero-btn-wrap">
                                 <a className="common-btn btn-hrrd-1" data-bs-toggle="modal"
                                    data-bs-target="#right_modal_sm">Get in touch</a>
                             </div>
                         </div>
                     </div>
                     <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                         <div className="hrrd-hero-img-wrap">
                             <div className="hrrd-hero-img position-relative">
                                 <img src="assets/images/cover-section-min_1.webp" alt="apls web development - Marketing and SEO" />
                                 <a className="play-btn position-absolute"  data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"></a>

                                 <div className="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModal" aria-hidden="true">
                                     <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                         <div className="modal-content bg-transparent border-0">
                                             <div className="modal-header">
                                                 <h5 className="modal-title" id="exampleModalLabel"></h5>
                                                 <button className="btn-close text-white" id="btn_close" data-bs-dismiss="modal" aria-label="Close">

                                                 </button>
                                             </div>
                                             <div className="modal-body p-0" id="modal_video">

                                                 <iframe id="video"
                                                         src="https://www.youtube.com/embed/bsnuAvG5qa0?mute=1&autoplay=1" allow="autoplay" unmute encrypted-media frameBorder="0"
                                                         allowFullScreen ></iframe>



                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                     <div className="hrrd-hero-post">
                                         <h4>Marketing and SEO</h4>
                                         <div className="hrrd-hero-post-shape">
                                             <img src="assets/images/shape/hrrd-hero-post.webp" alt="apls web development"/>
                                                 <a href="#"><i className="bi bi-download"></i></a>
                                         </div>
                                     </div>
                                     <div className="like-cart hero-like-cart">
                                         <div className="like-cart-box">
                                             <div className="like-cart-icon">
                                                 <i className="bx bxs-like"></i>
                                             </div>
                                             <div className="like-cart-content">
                                                 <h4>Software Development</h4>
                                             </div>
                                         </div>
                                     </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
             <img className="shape hrrd-hero-shape" src="assets/images/shape/hrrd-hero-shape.webp" alt="web development" />
             <img className="shape hrrd-hero-shape-top" src="assets/images/shape/hrrd-hero-shape-top.webp" alt="web development" />
         </div>

         <div className="hrrd-hero positioning d-lg-none d-block d-sm-block">
             <div className="container">
                 <div className="row align-items-center justify-content-between">
                     <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mobt-50">
                         <div className="hrrd-hero-content">
                             <h1 className="section-heading">Professional Website & Software Development</h1>
                             <p>Welcome to the world of automation where we automate the complete process using cross platform languages and AI. If you have an IT requirement, we have a solution.</p>
                             <div className="hrrd-hero-btn-wrap">
                                 <a className="common-btn btn-hrrd-1" data-bs-toggle="modal"
                                    data-bs-target="#right_modal_sm">Get in touch</a>
                             </div>
                         </div>
                     </div>
                     <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                         <div className="hrrd-hero-img-wrap-mobile">
                             <div className="hrrd-hero-img">
                                 <img src="assets/images/1.webp" alt="web development" />
                             </div>
                         </div>
                     </div>

                 </div>
             </div>
             <img className="shape hrrd-hero-shape" src="assets/images/shape/hrrd-hero-shape.webp" alt="image" />
         </div>
         <div className="tri-explore-bar">
             <div className="container">
                 <div className="row justify-content-end ">
                     <div className="col-xl-9 tri-explore-bar-row">
                         <div className="row" align="center">
                             <div className="col-md-4 col-sm-12 col-xs-12 pt-4 pb-4">
                                 <h4 className="text_1">Optimized Website Development</h4>
                                 <p>We provide fully optimized websites ready to rank on search engines. With better on-page SEO score and futuristic design, your website is ready to boost your business.</p>


                             </div>
                             <div className="col-md-4 col-sm-12 col-xs-12 pt-4 pb-4">
                                 <h4 className="text_1">Customized Software Development</h4>
                                 <p>Want to get your business to function smoothly? well we provide all the IT infrastructure and automation you need. Utilize the best of technology to make your business process seamless and automated.</p>

                             </div>
                             <div className="col-md-4 col-sm-12 col-xs-12 pt-4 pb-4">
                                 <h4 className="text_1">On line Marketing and SEO</h4>
                                 <p>Stay ahead of the crowed. We provide SEO services to rank you higher on search engines like Google&trade;, Bing&trade;, etc. Get optimized returns through our on-line marketing strategies.</p>

                             </div>

                         </div>
                     </div>
                 </div>
             </div>
         </div>
         <div className="everything-area positioning mt-100">
             <div className="container">
                 <div className="row justify-content-between align-items-center">
                     <div className="col-xxl-6 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                         <div className="everything-img-wrap">
                             <img
                                 src="assets/images/team-business_1.webp"
                                 alt="aPLS web development - get your business online" />

                         </div>
                     </div>
                     <div className="col-xxl-5 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 mobt-50">
                         <div className="trip-section-title wow animate fadeInDown" data-wow-delay="100ms"
                              data-wow-duration="1500ms">
                             <h2 className="section-heading">Get your business online and increase the coverage of your business</h2>
                         </div>
                         <div className="everything-info">
                             <ul>
                                 <li className="wow animate fadeInUp" data-wow-delay="100ms"
                                     data-wow-duration="1500ms">Real-time data <a href="#"><i
                                     className="bi bi-arrow-right"></i></a></li>
                                 <li className="wow animate fadeInUp" data-wow-delay="200ms"
                                     data-wow-duration="1500ms">End-to-end encryption <a href="#"><i
                                     className="bi bi-arrow-right"></i></a></li>
                             </ul>
                         </div>
                         <div className="hrrd-hero-btn-wrap">
                             <a className="common-btn btn-hrrd-1" data-bs-toggle="modal"
                                data-bs-target="#right_modal_sm">Get in touch</a>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
         <div className="collaborate-area positioning pt-100 ">
             <div className="container">
                 <div className="row justify-content-between align-items-center">
                     <div className="col-xxl-4 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
                         <div className="trip-section-title wow animate fadeInDown" data-wow-delay="100ms"
                              data-wow-duration="1500ms">
                             <h2 className="section-heading">We not just promise
                                 We deliver.
                             </h2>
                         </div>
                         <div className="collaborate-info">
                             <p>With our 100% delivery rate and happy customers, we can proudly say that "We create what
                                 you imagine!".</p>
                             <div className="row">
                                 <div
                                     className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 wow animate fadeInUp"
                                     data-wow-delay="100ms" data-wow-duration="1500ms">
                                     <div className="collaborate-info-list">
                                         <p><i className="bi bi-check"></i>A perfect Support system for every little
                                             niche</p>
                                     </div>
                                 </div>
                                 <div
                                     className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 wow animate fadeInUp"
                                     data-wow-delay="200ms" data-wow-duration="1500ms">
                                     <div className="collaborate-info-list">
                                         <p><i className="bi bi-check"></i>Connect with pre-qualified customers</p>
                                     </div>
                                 </div>
                                 <div
                                     className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 wow animate fadeInUp"
                                     data-wow-delay="300ms" data-wow-duration="1500ms">
                                     <div className="collaborate-info-list">
                                         <p><i className="bi bi-check"></i>Save time and money</p>
                                     </div>
                                 </div>
                                 <div
                                     className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 wow animate fadeInUp"
                                     data-wow-delay="400ms" data-wow-duration="1500ms">
                                     <div className="collaborate-info-list">
                                         <p><i className="bi bi-check"></i>We Deliver top Ranking On Search Engines</p>
                                     </div>
                                 </div>
                                 <div
                                     className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 wow animate fadeInUp"
                                     data-wow-delay="400ms" data-wow-duration="1500ms">
                                     <div className="collaborate-info-list">
                                         <p><i className="bi bi-check"></i>Highly customized solutions</p>
                                     </div>
                                 </div>
                             </div>
                         </div>
                         <div className="hrrd-hero-btn-wrap">
                             <a className="common-btn btn-hrrd-1" data-bs-toggle="modal"
                                data-bs-target="#right_modal_sm">Get in touch</a>
                         </div>
                     </div>
                     <div className="col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 mobt-50">
                         <div className="collaborate-img-wrap">
                             <img src="assets/images/shape/ezgif.com-gif-maker.webp" alt="aPLS web development - We not just promise
                                 We deliver"/>
                                 <div className="engagement-cart">
                                     <img src="assets/images/engagement-cart.webp" alt="image"/>
                                 </div>
                                 <div className="testimonial-cart">
                                     <div className="testimonial-cart-people">
                                         <h3>Jenifar Goz</h3>
                                         <h6>CEO, Xavale</h6>
                                     </div>
                                     <p>Everyone please read these condition ...</p>
                                     <i className="bx bxs-quote-right"></i>
                                 </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
         <div className="popular-jobs-area  pb-100 pt-100">
             <img className="shape popular-jobs-shape" src="assets/images/shape/popular.webp" alt="image" />
                 <div className="container ">
                     <div className="row mt-50 justify-content-center" align="center">
                         <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                             <div className="trip-service-box text-center home_service">
                                 <div className="trip-service-icon">
                                     <i className="fa fa-chart-bar icon_bg" aria-hidden="true"></i>
                                 </div>
                                 <div className="trip-service-content">
                                     <h2>Business Automation</h2>
                                 </div>
                                 <p>It only takes a glance at your smartphone to learn what the day holds for you.
                                     Mike makes managing and modifying schedules effortless for both individual calendars and the whole shop.
                                     Get your business online and improve productivity and flexibility. Enhance your workplace to the whole world.</p>
                             </div>
                         </div>
                         <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                             <div className="trip-service-box text-center home_service">
                                 <div className="trip-service-icon">
                                     <i className="fa fa-cogs icon_bg" aria-hidden="true"></i>
                                 </div>
                                 <div className="trip-service-content">
                                     <h2>Customized Software</h2>
                                 </div>
                                 <p>It only takes a click to get a software of your dreams created.
                                     If you have an idea that would make your business run smoother, we have a software created!</p>
                             </div>
                         </div>
                         <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                             <div className="trip-service-box text-center home_service">
                                 <div className="trip-service-icon">
                                     <i className="fa fa-paint-brush icon_bg" aria-hidden="true"></i>
                                 </div>
                                 <div className="trip-service-content">
                                     <h2>Custom Website Design</h2>
                                 </div>
                                 <p>Want to reach out to public?
                                     Internet is the most effective medium. We can help you reach maximum clients and establish you as a
                                     brand in the online space. Your Website is just a click away.</p>
                             </div>
                         </div>
                         <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                             <div className="trip-service-box text-center home_service">
                                 <div className="trip-service-icon">
                                     <i className="fa fa-bullseye icon_bg" aria-hidden="true"></i>
                                 </div>
                                 <div className="trip-service-content">
                                     <h2>SEO and Marketing</h2>
                                 </div>
                                 <p>Want your site to show up on top positions? We have a brilliant team expert
                                     at just that waiting to help you rank your sites. Start now and get most out of it.</p>
                             </div>
                         </div>
                         <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                             <div className="trip-service-box text-center home_service">
                                 <div className="trip-service-icon">
                                     <i className="fa fa-cogs icon_bg" aria-hidden="true"></i>
                                 </div>
                                 <div className="trip-service-content">
                                     <h2>Social Media Marketing
                                     </h2>
                                 </div>
                                 <p>Billy's 80% business is driven through Facebook™.
                                     We can help you establish your social presence in a short duration. Just the form below and lets start building your social quo.</p>
                             </div>
                         </div>
                         <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                             <div className="trip-service-box text-center home_service">
                                 <div className="trip-service-icon ">
                                     <i className="fa fa-lightbulb icon_bg" aria-hidden="true"></i>
                                 </div>
                                 <div className="trip-service-content">
                                     <h2>IT Support
                                     </h2>
                                 </div>
                                 <p>Need IT team to cover up technical support and queries? Well all you have to do is
                                     contact us using the form below. We train our support representatives to minimize
                                     your response time and optimize query resolution.</p>
                             </div>
                         </div>
                     </div>
                 </div>
         </div>

         <div className="container pb-76">
             <div className="row">
                 <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                     <div className="trip-section-title text-center wow animate fadeInDown" data-wow-delay="100ms"
                          data-wow-duration="1500ms">
                         <h2 className="section-heading">Our Process</h2>
                         <p>Start the first step of the process by filling in the requirement form below. <br/>Our
                             fabulous team is waiting at other end to welcome you to our family.</p>
                     </div>
                 </div>
             </div>
         </div>
         <OwlCarousel id="owl-demo" className="owl-carousel owl-theme pb-5" {...options}>
             <div className="item" align="center">
                 <div className="ag-testimonial-card">
                     <h4 className="testiminial-texts">“Excellent professional work. Very knowledgeable
                         and fast response and ahead of expected time. Will use their services again.
                         Highly recommended! Dr Benny Morris”.</h4>
                     <h5 className="reviewer-name">Dr. Benny Morris</h5>
                     <span>Best Selling Self Help Author, Social Media Marketer</span>
                 </div>
             </div>
             <div className="item" align="center">
                 <div className="ag-testimonial-card">
                     <h4 className="testiminial-texts">"Professional Work and nice website. Would love to work with them again. Thanks."</h4>
                     <h5 className="reviewer-name">Shary Rose</h5>
                     <span>Musician</span>
                 </div>
             </div>
         </OwlCarousel>

         <Getintouch />
     </>

    )
}
export default Home