import React, { Component } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Outlet
} from "react-router-dom";
import Sidebar from './Sidebar';
import favicon from './favicon.png';
import logo from './logo.webp';
const Navbar = () => {

    return (
        <>
            <header>
                <div className="header-menu-area hrrd-menu container-fluid ">

                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark p-0 ">
                            <Link to="/"><img className="logo" src={logo} alt="apls web development"  /></Link>
                            <button className="navbar-toggler my-2" type="button" data-bs-toggle="collapse"
                                data-bs-target="#main_nav"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse justify-content-center lh-lg" id="main_nav">
                                <ul className="navbar-nav p-3 p-md-0">
                                    <li className="nav-item"><Link to="/" className="nav-link ">Home</Link></li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#"
                                            data-bs-toggle="dropdown">Industries </a>
                                        <ul className="dropdown-menu">
                                            <li><Link to="/fintech" className="dropdown-item">Fintech</Link></li>
                                            <li><Link to="/education" className="dropdown-item">Education</Link></li>
                                            <li><Link to="./retail-and-eCommerce" className="dropdown-item">Retail and eCommerce</Link>
                                            </li>
                                            <li><Link to="./transport" className="dropdown-item">Transportation</Link></li>
                                            <li><Link to="./medical" className="dropdown-item">Medical </Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item dropdown ktm-mega-menu">
                                        <a className="nav-link dropdown-toggle text-dark" href="#"
                                            data-bs-toggle="dropdown">Services </a>
                                        <span className="dropdown-menu mega-menu">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <ul className="sub-menu">

                                                        <li><Link to="/enterprise-application" >Enterprise Applications</Link></li>
                                                        <li><Link to="/website-development">Website Development</Link></li>
                                                        <li><Link to="/software-development">Software Development</Link></li>
                                                        <li><Link to="/mobile-application">Mobile Application Development</Link></li>
                                                        <li><Link to="/customised-software-development">Customised Software Development</Link></li>
                                                        <li><Link to="/data-science-and-analytics">Data Science and Analytics</Link></li>
                                                    </ul>
                                                </div>

                                                <div className="col-md-3">
                                                    <ul className="sub-menu">
                                                        <li><Link to="/digital-marketing">Digital marketing</Link></li>
                                                        <li><Link to="/automation">Automation and AI</Link></li>
                                                        <li><Link to="/cloud">Cloud</Link></li>
                                                        <li><Link to="/business-autmation">Business Automation</Link></li>
                                                        <li><Link to="/itsupport">IT Support</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6 bg1" ></div>

                                            </div>
                                        </span>
                                    </li>
                                    <li className="nav-item "><Link to="/discover-aPLS" className="nav-link ">Discover aPLS</Link></li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#"
                                           data-bs-toggle="dropdown">Community </a>
                                        <ul className="dropdown-menu">
                                            <li><Link to="/careers" className="dropdown-item">Careers</Link></li>
                                            <li><Link to="/ngo-we-love-andsupport" className="dropdown-item">NGOs we love and support</Link></li>
                                            <li><Link to="/community-center" className="dropdown-item">Community Center</Link>
                                            </li>
                                        </ul>
                                    </li>


                                    <li className="nav-item "><Link to="/news" className="nav-link ">News</Link></li>
                                </ul>
                                <a className="common-btn btn-hrrd-1 test" data-bs-toggle="modal"
                                   data-bs-target="#right_modal_sm">Get in touch</a>
                                {/*<a className="common-btn btn-hrrd-1 test" data-bs-toggle="modal"*/}
                                {/*    data-bs-target="#right_modal_sm">Get in touch</a>*/}
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
            <Sidebar />
        </>
    );
}

export default Navbar