import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Outlet
} from "react-router-dom";
import BookItem from './BookItem';

const Posts = ({ posts, loading }) => {

    if (loading) {
        return <h4 className="text-center">Loading...</h4>;
    }

    return (
        <>
            {posts.map(post => (
                <BookItem key={post.id} book={post} />
            ))}
            </>

    );
};

export default Posts;