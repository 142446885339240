import React from "react";

const Education = () =>{
    return(
       <>
           <div className="pt-100 pb-76" align="center">
               <img src="assets/images/commingsoon-min.png" />
                <h2 className="text-primary"> Coming soon!</h2>
               <h6><i>This site is currently under-construction.</i></h6>
           </div>
       </>
    )
}
export default Education