import React from "react";
import { Helmet } from "react-helmet";
import Getintouch from "./Getintouch";

const About = () => {
    return (
        <>
            <Helmet>
                <title>aPLS web development - Discover aPLS</title>
                <meta name="description" content="We Create what you imagine!" />
                <meta name="keywords" content="about aPLS web development" />
            </Helmet>
            <div className="hrrd-hero_1 positioning d-lg-block bg_half_2  ">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                            <div className="hrrd-hero-content">

                                <h1 className="section-heading">Discover aPLS</h1>
                                <p className="pt-4">We are a remarkable digital transformation, product and consultancy company constantly exploring ways to enhance the lives of our world workforce. We help our corporate customers to make the most of their investments in technology.</p>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-center">
                            <div className="hrrd-hero-img-wrap">
                                <div className="appointment-img">
                                    <img src="assets/images/apls1.webp" alt="Discover aPLS" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="shape hrrd-hero-shape" src="assets/images/shape/hrrd-hero-shape.png"
                    alt="web development" />
                <img className="shape hrrd-hero-shape-top" src="assets/images/shape/hrrd-hero-shape-top.png"
                    alt="web development" />
            </div>
            <div className="space-bar"></div>

            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                        <div className="about-img-2">
                            <img
                                src="assets/images/about3-min.jpg"
                                alt="Images" />
                        </div>
                    </div>
                    <div className="col-md-5 offset-md-1">
                        <h2 className="section-heading">Our vision

                        </h2>
                        <p className="section-paragraph pt-4">
                            aPLS was created from the beginning to be a specialist in developing new products and R&D, dealing with the most challenging issues in the technology field. The company has expanded to include the early stages of innovation and creativity and business consulting for digital transformation. Our strength is in delivering everything in one place on a global scale. Let's start now and create a better world by working together. We're diverse and multicultural, thanks to our diverse areas. What is the driving force behind us? A common vision, identical values and corporate culture, as well as management processes and practices across the borders. Our goal is to help make it simpler for people to make use of the power of analytics each day in order to reduce the time from data to insight and to encourage the most innovative discoveries that fuel the way forward. This results in analytics that breaks down barriers that hinder progress and fuels goals. Results.

                        </p>

                    </div>
                </div>
            </div>
            <div className="space-bar"></div>
            <div className="container">
                <div className="row align-items-center justify-content-between">

                    <div className="col-md-6">
                        <h2 className="section-heading">To empower and inspire with the most trusted analytics.
                        </h2>
                        <p className="section-paragraph pt-4">
                            Our goal is to provide a better environment for many people." This is simple, concise and concise. In addition, it establishes the tone of the business and makes apparent that they're on the market to provide low-cost furniture suitable for every person's needs.We're determined to ensure the maximum number of eligible non-profit organizations can benefit from aPLS Business Cloud as possible so that they can act.Change can begin in a staffed business with the right people and a positive outlook. aPLS is a group of tech-savvy and creative people who have been creating durable solutions with long-lasting value for over eight years. We're unstoppable in finding the best method to turn your dream into a huge success story that crosses boundaries and time zone.

                        </p>

                    </div>
                    <div className="offset-1 col-md-5 ">
                        <div className="about-img-2">
                            <img
                                src="assets/images/about.webp"
                                alt="Images" />
                        </div>

                    </div>
                </div>
            </div>
            <div className="space-bar"></div>

            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                        <div className="about-img-2">
                            <img
                                src="assets/images/sports.webp"
                                alt="Images" />
                        </div>
                    </div>
                    <div className="col-md-5 offset-md-1">
                        <h2 className="section-heading">
                        Not just an IT company. We are a professional sports team.
                        </h2>
                        

                    </div>
                </div>
            </div>
            <div className="space-bar"></div>
            <div className="container">
                <div className="row align-items-center justify-content-between">

                    <div className="col-md-6">
                        <h2 className="section-heading">
                        How will transforming your townhalls into must-attend events help you drive employee engagement and your desired business outcomes                        
                        </h2>
                       

                    </div>
                    <div className="offset-1 col-md-5 ">
                        <div className="about-img-2">
                            <img
                                src="assets/images/AD-min.webp"
                                alt="Images" />
                        </div>

                    </div>
                </div>
            </div>
            <div className="space-bar"></div>

            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                        <div className="about-img-2">
                            <img
                                src="assets/images/Thank-min.webp"
                                alt="Images" />
                        </div>
                    </div>
                    <div className="col-md-5 offset-md-1">
                        <h2 className="section-heading">
                        Events and festival celebrations at aPLS web development

                        </h2>
                       

                    </div>
                </div>
            </div>
            <div className="space-bar"></div>
            <div className="container">
                <div className="row align-items-center justify-content-between">

                    <div className="col-md-6">
                        <h2 className="section-heading">
                        Events and festival celebrations at aPLS web development                        </h2>
                       

                    </div>
                    <div className="offset-1 col-md-5 ">
                        <div className="about-img-2">
                            <img
                                src="assets/images/AD-min11.webp"
                                alt="Images" />
                        </div>

                    </div>
                </div>
            </div>
            <div className="space-bar"></div>
          

            <div className="edu-client-logo-style">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <h2 className="edu-section-title text-center">Technology we use</h2>
                        </div>
                    </div>
                    <div className="edu-client-logo-row mt-5">
                        <div className="edu-client-logobox">

                            <img src="assets/images/laravel.png" alt="aPLS web development - techonology" width="50%" />

                        </div>
                        <div className="edu-client-logobox">

                            <img src="assets/images/techonology/wp.png" alt="aPLS web development - techonology" width="50%" />

                        </div>
                        <div className="edu-client-logobox">

                            <img src="assets/images/techonology/react.png" alt="aPLS web development - techonology" width="50%" />

                        </div>
                        <div className="edu-client-logobox">

                            <img src="assets/images/techonology/vue.png" alt="aPLS web development - techonology" width="50%" />

                        </div>

                    </div>
                    <div className="edu-client-logo-row mt-5">
                        <div className="edu-client-logobox">

                            <img src="assets/images/techonology/mogodb.png" alt="aPLS web development - techonology" width="50%" />

                        </div>
                        <div className="edu-client-logobox">

                            <img src="assets/images/techonology/python.png" alt="aPLS web development - techonology" width="50%" />

                        </div>
                        <div className="edu-client-logobox">

                            <img src="assets/images/techonology/flutter.png" alt="aPLS web development - techonology" width="50%" />

                        </div>
                        <div className="edu-client-logobox">

                            <img src="assets/images/techonology/cordova.png" alt="aPLS web development - techonology" width="50%" />

                        </div>
                    </div>
                </div>
            </div>
            <Getintouch />

        </>
    )
}
export default About 