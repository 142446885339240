import React from "react";

const Contact = () =>{
    return(
        <>
            <div className="edu-register-style position-relative pb-5 ">
                <div className="edu-register-flort-bg d-none d-lg-block"></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <img src="assets/images/contact.webp"/>
                        </div>
                        <div className=" col-md-7 d-flex justify-content-center">
                            <div className="row align-content-center">
                                <div className="col-xxl-8 col-lg-8 position-relative">
                                    <h6 className="register-section-title edu-section-title text-start ">
                                        We would love you message in our inbox
                                    </h6>
                                    <p className="pt-3">
                                        We love helping enterprises on identifying use cases that can help them towards their business goals. You can reach out to us to discuss your use case. Click on the "Get in touch button" and submit your query.                                     </p>

                                    <div className="arrow-vactor d-none d-lg-block">
                                        <img src="assets/images/education/shapes/arrow-vactor.webp" alt="aPLS web development - get in touch" />
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-lg-4">
                                    <div className="register-btn text-lg-center">
                                        <a href="#" className="edu-btn-fill" data-bs-toggle="modal"
                                           data-bs-target="#right_modal_sm">Get in touch</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Contact