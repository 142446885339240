import React ,{useState, useEffect,useRef } from "react";
import { useParams } from "react-router-dom";
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Outlet
} from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as yup from "yup";
import KErrorMessage from './KErrorMessage';
import * as Yup from "yup";
import axios from "axios";



const FILE_SIZE = 5000000;//5MB
const SUPPORTED_FORMATE = ['application/pdf'];
const validationSchema = yup.object({
    name: yup.string().required("Name is Required"),
    email: Yup.string().email('Invalid email address').required('Email is Required'),
    phone: yup
        .number()
        .min(1000000000, "Not Valid Phone Number")
        .max(9999999999, "Not Valid Phone Number")
        .required("Mobile number is Required"),
    file:Yup.mixed()
        .required('Required')
        .test("FILE_SIZE", "Uploaded file is too big.",
            (value) => !value || (value && value.size <= FILE_SIZE)
        )
        .test("FILE_FORMAT", "Uploaded file has unsupported format.",
            (value) => !value || (value && SUPPORTED_FORMATE.includes(value?.type))
        )
});


const Product = () => {



    const {id} = useParams();
    console.log(id);
    const [product, setProduct] =useState([]);
    const [loading, setLoading] =useState(false);
    const [loading1, setLoading1] =useState(false);
    const [inprogress, setInprogress] =useState(false);


    useEffect(() => {
        const getProduct = async () =>{
            setLoading(true);
           // const response = await fetch(`https://fakestoreapi.com/products/${id}`);

            const response = await fetch(`https://aplswd.com/api/jobdetails.php?id=${id}`);

            console.log(response);
            setProduct(await response.json());
            setLoading(false);
        }
        getProduct();

    }, []);

    const Loading = () => {
        return(
            <p className="pt-5 text-center"> Please wait for a movement....</p>
        )
    };



    const ShowProduct = () => {



        const fileref = useRef(null);
        return (
            <>
                <div className="row">
                    <div className="col-md-8">

                        <h3 className="section-heading ">Position you are applying for {product.title}</h3>

                        <hr/>
                        <h6><b>
                            Job Description
                        </b>
                        </h6>
                        <p className="section-paragraph pt-1">
                            {product.description}
                        </p>
                        <br/>
                        <h6><b>
                            Key Responsibilities
                        </b>
                        </h6>
                        <p className="section-paragraph">
                            {product.responsibilities}
                        </p>
                        <br/>
                        <div className="p-4 apply_form">
                            <h4 className="text-orange">Apply for the job</h4>
                            <p>
                                <small>
                                    Fill in the form below to apply for the job.
                                </small>
                            </p>
                            <br />
                            <Formik
                                validationSchema={validationSchema}
                                initialValues={{
                                    name: "",
                                    phone: "",
                                    email: "",
                                    file: "",
                                    post: "",
                                    experience: "",
                                }}

                                onSubmit={(values) => {
                                    console.log(values);
                                    console.log(values.name);
                                    setInprogress(true);

                                    const formData = new FormData();
                                    formData.append('file',values.file);
                                    formData.append('name',values.name);
                                    formData.append('phone',values.phone);
                                    formData.append('email',values.email);
                                    formData.append('post',values.post);
                                    formData.append('experience',values.experience);


                                    console.log(formData);
                                    const axios = require('axios');

                                    const response = axios.post(
                                        'https://aplswd.com/api/jobpost.php',
                                        formData,
                                        {

                                            headers : {
                                                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                                            }
                                        }
                                    )  .then(function (response) {
                                        //console.log(response);
                                        //alert('done');
                                        setInprogress(false);
                                        setLoading1(true);
                                        setTimeout(() => {
                                            setLoading1(false);
                                        }, 3000);
                                    })
                                        .catch(function (error) {
                                            // console.log(error);
                                        });


                                }}
                            >
                                {({ values,setFieldValue }) => (
                                    <Form >

                                        <div className="col-xl-12 col-lg-12 col-sm-12 col-12 pt-4">
                                            <h6 className="mb-1"> Name</h6>
                                            <Field name="name" type="text" className="form-control"/>
                                            <KErrorMessage name="name" />
                                        </div>

                                        <div className="col-xl-12 col-lg-12 col-sm-12 col-12 pt-4">
                                            <h6 className="mb-1">Email Address</h6>
                                            <Field name="email" type="text" className="form-control"/>
                                            <KErrorMessage name="email" />
                                        </div>

                                        <div className="col-xl-12 col-lg-12 col-sm-12 col-12 pt-4">
                                            <h6 className="mb-1">Mobile Number</h6>
                                            <Field name="phone" type="text" className="form-control"/>
                                            <KErrorMessage name="phone" />
                                        </div>

                                        <div className="col-xl-12 col-lg-12 col-sm-12 col-12 pt-4">
                                            <h6 className="mb-1">Post Name</h6>
                                            <Field name="post" type="text" className="form-control"/>

                                        </div>

                                        <div className="col-xl-12 col-lg-12 col-sm-12 col-12 pt-4">
                                            <h6 className="mb-1">Your Experience</h6>
                                            <Field name="experience" type="text" className="form-control"/>
                                        </div>


                                        <div className="col-xl-12 col-lg-12 col-sm-12 col-12 pt-4">
                                            <h6 className="mb-1">Your resume (PDF)
                                            </h6>
                                            <input
                                                ref={fileref}
                                                type="file"
                                                className="form-control"
                                                onChange={(event) => {
                                                    setFieldValue("file", event.target.files[0]);
                                                }}
                                            />
                                            <KErrorMessage name="file" />
                                        </div>
                                        <div className="d-grid gap-2 pt-4">
                                            <button type="submit" className="btn btn-primary">{inprogress ?  <><span className="spinner-border text-white spinner-border-sm"></span> Please wait....</> : "Submit"}</button>
                                        </div>
                                    </Form>


                                )}
                            </Formik>

                        </div>
                        <div>
                            {loading1 ?
                                <div className=" alert-dismissible fade show">Your application submit <strong>successfully!</strong></div>

                                : <></>
                            }
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="cart-wrap">
                            <div className="single-cart">
                                <div className="cart-title">
                                    <h2 className="section-heading">Short Info
                                    </h2>
                                </div>
                                <div className="contact-details">

                                    <div className="contact-details-info">
                                        <ul>
                                            <li><i className="bi bi-check"></i> {product.type}
                                            </li>
                                            <li><i className="bi bi-check"></i> {product.experience}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="contact-details">
                                    <div className="contact-details-title">
                                        <h3>Office Address</h3>
                                    </div>
                                    <div className="contact-details-info">
                                        <ul>
                                            <li><i className="bi bi-geo-alt-fill"></i> Plot No - 13 , Ramchandranagar,
                                                shahnoorwadi, Aurangabad.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
    return(
        <div>
            <div className="hrrd-hero_1  positioning ">
                <div className="container-fluid bg-gray">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                                <div className="hrrd-hero-content">
                                    <h1 className="section-heading">Careers</h1>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-center">
                                <p><a href="#">Community </a>/<b><a href="#">Career</a> </b></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container">

                {loading ? <Loading/> : <ShowProduct/>}

            </div>

            <div className="space-bar"></div>

        </div>
    );
}

export default Product
