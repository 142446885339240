import React, {useState} from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as yup from "yup";
import KErrorMessage from "./KErrorMessage";

const validationSchema = yup.object({
    name: yup.string().required("Your name is required"),
    email: yup.string().email('Please enter valid email address').required('Please enter email address'),
    institution : yup.string().required("Institution Name is required"),
    server: yup.string().required("Please select required server"),
    phone: yup
        .number()
        .min(1000000000, "Not Valid Phone Number!")
        .max(9999999999, "Not Valid Phone Number!")
        .required("Phone is Required!"),
    region: yup.string().required("Please select required region "),

    software: yup.string().required("Please select required software"),
    about: yup
        .string()
        .min(5, "too small!")
        .max(500, "Too Long String")
        .required("message is required"),

});
const App = () => {

    const [inprogress, setInprogress] =useState(false);

    const [inprogress1, setInprogress1] =useState(false);

    const [form_content, setForm_content] =useState(false);

    return (
        <div>
            <Formik
                validationSchema={validationSchema}
                initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    region: "",
                    software: "",
                    about: "",
                    institution: "",
                    server: "",

                }}
                onSubmit={(values) => {
                    setInprogress(true);
                   // console.log(JSON.stringify(values));
                    const data = JSON.stringify(values)
                   // console.log(values.name);
                    const axios = require('axios');

                    const response = axios.post(
                        'https://aplswd.com/api/form.php',
                        data,
                        {
                            headers : {
                                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                            }
                        }
                    ) .then(function (response) {
                        console.log(response);
                        setInprogress(false);
                        setForm_content(true);
                        setInprogress1(true);
                        // setTimeout(() => {
                        //     setInprogress1(false);
                        //     setForm_content(false);
                        // }, 3000);

                    })
                        .catch(function (error) {
                            console.log(error);
                        });

                }}
            >
                {({ values }) => (
                    <div className="modal right fade show" id="right_modal_sm" data-bs-backdrop="static" data-bs-keyboard="false"
                         tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-sm modal_line">
                            <div className="modal-content">

                                <div className="modal-body" id="modal-body">

                                    <div className="modal-header" align="center">
                                        <h5 className="modal-title" id="exampleModalLabel">Choose (your) Purpose</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="form_content">
                                        {form_content ?  <> </>:
                                            <>
                                            <p className="text-center">Select the suitable solution for us to get back with a quote.</p>
                                            <Form>
                                            <br/>
                                            <div className="col-xl-12 col-lg-12 col-sm-12 col-12">
                                            <h6 className="mb-1">Select solution
                                            </h6>
                                            <p className="small text-muted mb-3"> Select from range of solutions we offer.</p>
                                            <Field name="software" as="select" className="form-control">
                                            <option value="0">Select solution</option>
                                            <option value="Enterprise_Applications">Enterprise Applications </option>
                                            <option value="Website_Development">Website Development</option>
                                            <option value="Software_Development">Software Development</option>
                                            <option value="Mobile_Application_Development">Mobile Application Development</option>
                                            <option value="Customised_Software_Development">Customised Software Development</option>
                                            <option value="Data_Science_and_Analytics">Data Science and Analytics</option>
                                            <option value="Digital_marketing">Digital marketing</option>
                                                <option value="Automation_and_AI">Automation and AI</option>
                                                <option value="Cloud">Cloud</option>
                                                <option value="Business_Automation">Business Automation</option>
                                                <option value="IT_Support">IT Support</option>
                                                <option value="custom">I have a custom requirement</option>
                                            </Field>
                                            <KErrorMessage name="software" />
                                            </div>
                                                <div className="row">
                                                    <div className="col-xl-12 col-lg-12 col-sm-12 col-12 pt-3 pb-3">
                                                        <h6 className="mb-1">Region</h6>
                                                        <p className="small text-muted mb-3">Select Region.</p>
                                                        <div className="button1">
                                                            <Field name="region" value="USA" type="radio" />
                                                            <label className="btn btn-default" htmlFor="USA">USA</label>
                                                        </div>
                                                        <div className="button1">
                                                            <Field name="region" value="EUROPE" type="radio" />
                                                            <label className="btn btn-default" htmlFor="EUROPE">EUROPE</label>
                                                        </div>
                                                        <div className="button1">
                                                            <Field name="region" value="South_East_Asia" type="radio" />
                                                            <label className="btn btn-default" htmlFor="South_East_Asia">South East Asia</label>
                                                        </div>
                                                        <div className="button1">
                                                            <Field name="region" value="Middle_East" type="radio" />
                                                            <label className="btn btn-default" htmlFor="Middle_East">Middle East</label>
                                                        </div>
                                                        <KErrorMessage name="region" />
                                                    </div>
                                                </div>

<div className="row">
    <div className="col-xl-12 col-lg-12 col-sm-12 col-12 ">

        <h6 className="mb-1">Prefer server</h6>
        <p className="small text-muted mb-3">Select the type of installation required.</p>
        <div className="button2">
            <Field name="server" value="Cloud" type="radio" />
            <label className="btn btn-default" htmlFor="Cloud">Cloud</label>

        </div>
        <div className="button2">
            <Field name="server" value="local_server" type="radio" />
            <label className="btn btn-default" htmlFor="Local Server">Local Server</label>

        </div>
        <div className="button2">
            <input type="radio" id="VPS" value="VPS" name="server"/>
            <label className="btn btn-default" htmlFor="VPS">VPS</label>
        </div>
        <KErrorMessage name="server" />
    </div>
</div>

<div className="row">
    <div className="col-xl-12 col-lg-12 col-sm-12 col-12 pt-1">

        <h6 className="mb-1">Email Address</h6>
        <Field name="email" type="text" className="form-control"/>
        <KErrorMessage name="email"  />
    </div>
</div>


                                            <div className="col-xl-12 col-lg-12 col-sm-12 col-12 pt-4">
                                            <h6 className="mb-1">Institution Name</h6>
                                            <Field name="institution" type="text" className="form-control"/>
                                            <KErrorMessage name="institution" />
                                            </div>

                                            <div className="col-xl-12 col-lg-12 col-sm-12 col-12 pt-4">
                                            <h6 className="mb-1">Your Name</h6>
                                            <Field name="name" type="text" className="form-control"/>
                                            <KErrorMessage name="name" />
                                            </div>

                                            <div className="col-xl-12 col-lg-12 col-sm-12 col-12 pt-4">
                                            <h6 className="mb-1">Phone number with Country Code</h6>
                                            <Field name="phone" type="number" className="form-control"/>
                                            <KErrorMessage name="phone" />
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-sm-12 col-12 pt-4">
                                            <h6 className="mb-1">Message for us</h6>
                                            <Field name="about" as="textarea" className="form-control"/>
                                            <KErrorMessage name="about" />
                                            </div>
                                            <br/>
                                            <div className="d-grid gap-2 ">
                                            <button type="submit" className="btn btn-primary">{inprogress ? <><span
                                            className="spinner-border text-white spinner-border-sm"></span> Please
                                            wait....</> : "Submit"}</button>
                                            </div>
                                            <br/>
                                            </Form>
                                            </>
                                        }
                                    </div>

                                    <div className="success_msg text-center">
                                        {inprogress1 ?  <>
                                            <p className="text-center">
                                                <img src="assets/images/195.png" width="70%" />
                                            </p>
                                            <h6>Your Request submit successfully !</h6>
                                        </> : <></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )}
            </Formik>
        </div>
    );
};

export default App;
