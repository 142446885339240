import React ,{useState, useEffect,useRef } from "react";
import { useParams } from "react-router-dom";
import {
	BrowserRouter,
	Routes,
	Route,
	Link,
	Outlet
} from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import {Helmet} from "react-helmet";

import axios from "axios";
import Recent from "./Recent";
import BookItem from "./BookItem";


const Product = () => {
;
	const [loading, setLoading] = useState(false);

	const params =useParams();
	const [title,setTitle]=useState("");
	const [content,setContent]=useState("");
	useEffect(() => {
			const fetchPosts = async () => {
				setLoading(true);
				const res = axios
					.get(`https://aplswd.com/blog/wp-json/wp/v2/posts?slug=${params.slug}`) // you can access it like this
					.then((res) => {
						console.log("DATA: ", res.data);
						setTitle(res.data[0].title);
						console.log(res.data[0].title);
						setContent(res.data[0].content);
						setLoading(false);
					})
					.catch(error=>console.log(error))


			};

			fetchPosts();
		}, []);



	const Loading = () => {
		return(
			<div className="breadcrumb-area positioning loader_height">
				<div className="container">
					<div className="row">
						<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="breadcrumb-text text-center">
								<h4>Please wait for a moment...</h4>

							</div>
						</div>
					</div>
				</div>

			</div>
		)
	};



	return(

		<div>
			<Helmet>
         <title>{title.rendered}</title>
		 
 <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
<link rel="canonical" href="https://aplswd.com/blog/top-5-emerging-technology-trends-in-2023" />
<meta property="og:locale" content="en_US" /> <meta property="og:type" content="article" />
<meta property="og:title" content="Top 5 emerging Technology trends in 2023 - aPLS web development" />
<meta property="og:description" content="Top 5 emerging Technology trends in 2023 Artificial intelligence and machine learning With the increasing availability of data and computing power, AI and machine learning will become more prevalent in a wide range of industries and applications, including healthcare, finance, and retail. Artificial intelligence (AI) and machine learning (ML) have been buzzwords in the tech &hellip; Top 5 emerging Technology trends in 2023 Read More &raquo;" />
<meta property="og:url" content="https://aplswd.com/blog/top-5-emerging-technology-trends-in-2023" />
<meta property="og:site_name" content="aPLS web development" />
<meta property="article:published_time" content="2022-12-23T12:11:38+00:00" />
<meta property="article:modified_time" content="2022-12-26T12:52:41+00:00" />
<meta property="og:image" content="https://aplswd.com/blog/wp-content/uploads/2022/12/Skype_Picture_2022_12_23T12_44_32_327Z.png" />
<meta property="og:image:width" content="1024" />
<meta property="og:image:height" content="1024" />
<meta property="og:image:type" content="image/png" /><meta name="author" content="Ashish Ranjan" />
<meta name="twitter:card" content="summary_large_image" /><meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Ashish Ranjan" /><meta name="twitter:label2" content="Est. reading time" />
<meta name="twitter:data2" content="7 minutes" /> 
</Helmet>
			{loading ? <Loading/> :
				<>
			<div className="positioning ">
				<div className="container-fluid blog">
					<div className="container">
						<div className="row align-items-center justify-content-between">
							<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
								<div className="hrrd-hero-content text-center">
									<h1 className="section-heading">{title.rendered}</h1>

									<p><Link to="/">Home</Link> / <Link to="/news" >News</Link></p>
								</div>
							</div>
							<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">

							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="space-bar"></div>

			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="blog-details-area">
							<div className="container">
								<div className="row">
									<div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12" ></div>
									<div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12" >
										<div className="blog-post-wrap blog-post-details">
											<h2 className="pb-4 section-heading">{title.rendered}</h2>
											<div dangerouslySetInnerHTML={{ __html: content.rendered }}/></div>
										</div>

								</div>
							</div>
						</div>

					</div>

				</div>
			</div>

			<div className="space-bar"></div>
			</>
			}
		</div>
	);
}

export default Product
